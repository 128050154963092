{
  "button": {
    "back": "חזור",
    "cancel": "ביטול",
    "confirm": "אישור",
    "fullscreen_enter": "היכנס למסך מלא",
    "fullscreen_exit": "צא ממסך מלא",
    "refresh": "רענון",
    "toggle_dark": "מצב כהה",
    "toggle_langs": "שנה שפות",
    "remove": "לְהַסִיר",
    "clear_all": "נקה הכל",
    "delete": "מחיקה",
    "edit": "לַעֲרוֹך",
    "buy_now": "קנה עכשיו",
    "enter_another_card": "הזן כרטיס אחר",
    "try_again": "נסה שוב",
    "enter_another_code": "הזן קוד אחר",
    "submit_code": "שלח קוד",
    "download": "הורד",
    "no": "לא",
    "go_to_homepage": "עבור לעמוד הבית",
    "slide_back": "שקופית קודמת",
    "slide_forward": "השקף הבא",
    "exit": "יְצִיאָה",
    "full": "מלא",
    "regular": "רגיל",
    "rename": "שנה שם",
    "get": "להורדה"
  },
  "common": {
    "loading": "טעינה...",
    "loading_failed": "הטעינה נכשלה",
    "empty_search": "לא נמצאו תוצאות עם כותרת \"{searchValue}\".",
    "empty_list": "הרשימה ריקה.\nתחזור מאוחר יותר.",
    "data_not_found": "הנתונים לא נמצאו או שהשרת לא מגיב... אנא נסה שוב מאוחר יותר.",
    "empty_events_list": "אין עדיין אירועים",
    "get_mobile_app": "הצטרפו לאפליקציית איגוד הכדורסל!"
  },
  "not-found": "לא נמצא",
  "locales": {
    "en": "English",
    "de": "German",
    "es": "Spanish",
    "uk-UA": "Ukrainian",
    "pl": "Polish",
    "fr": "French",
    "pt": "Portuguese",
    "he": "Hebrew",
    "it": "Italian",
    "ko": "Korean",
    "zh": "Chinese",
    "bs": "Lokalni",
    "title": "בחר שפת אתר",
    "ca": "Catalan"
  },
  "baseHeader": {
    "menu": {
      "account": "החשבון שלי",
      "language": "שפה: {currentLanguage}",
      "logout": "התנתק",
      "manage_following": "ניהול מועדפים",
      "select_language": "בחירת שפה",
      "selected_videos": "סרטונים נבחרים",
      "my_clips_and_highlights": "קליפים וקטעים נבחרים שלי"
    }
  },
  "labels": {
    "sign_in": "התחבר",
    "sign_up": "הרשמה",
    "continue": "המשך",
    "email": "מייל",
    "forgot_password": "שכחתי את הסיסמה",
    "password": "סיסמה",
    "date_added": "תאריך עדכון",
    "highlights": "חלק נבחר | קטעים נבחרים",
    "latest": "משחקים אחרונים",
    "live": "שידור חי",
    "password_new": "סיסמה חדשה",
    "password_old": "סיסמה ישנה",
    "password_repeat": "חזרה על הסיסמה",
    "save": "שמור",
    "see_all": "לרשימה המלאה >",
    "upcoming": "אירועים קרובים",
    "update": "עדכון",
    "country": "מדינה",
    "phone": "טלפון",
    "search": "חיפוש",
    "load_more": "טען עוד",
    "follow": "עקוב",
    "following": "מועדפים",
    "total": "סך הכל",
    "all": "הכל",
    "sport_type": "ענף ספורט",
    "full_name": "שם מלא",
    "newest": "החדש ביותר",
    "oldest": "הישן ביותר",
    "modal_labels": {
      "delete_clip": "למחוק את הוידאו?",
      "delete_highlight": "למחוק את הוידאו?",
      "delete_profile": "למחוק את הפרופיל?",
      "delete_tag": "האם אתה בטוח שברצונך להסיר את התגים האלה? |\nהאם אתה בטוח שברצונך להסיר את התג הזה?"
    },
    "active": "פעיל",
    "submit": "שלח",
    "purchase": "רכישה",
    "confirm_new_password": "אישור סיסמה חדשה",
    "enter_new_password": "הגדרת סיסמה חדשה",
    "dark": "כהה",
    "light": "בהיר",
    "powered_by": "מופעל על ידי",
    "policy": "מדיניות הפרטיות",
    "terms": "תנאי השימוש",
    "next": "הבא",
    "search_input_placeholder": "קבוצה, משחק, מועדון וכו'",
    "skip": "דלג",
    "amount": "כמות",
    "date": "תאריך",
    "type": "סוג",
    "continue_with": "התחבר עם {provider}",
    "or": "אוֹ",
    "anytime": "בכל זמן",
    "event_date": "תאריך המשחק",
    "past_30_days": "30 הימים האחרונים",
    "past_7_days": "7 הימים האחרונים",
    "past_90_days": "90 הימים האחרונים",
    "today": "היום",
    "yesterday": "אתמול",
    "all_results": "כל התוצאות",
    "events": "משחקים",
    "views": "אין צפיות | {count} צפייה | {count} צפיות",
    "clip": "קליפ |  קליפים",
    "tag": "תג |  תגים",
    "game_videos": "קליפים",
    "bookmark_add": "הוסף לנבחרים",
    "bookmark_remove": "הסר מהנבחרים",
    "share": "שיתוף",
    "days": "ימים",
    "hours": "שעות",
    "minutes": "דקות",
    "seconds": "שניות",
    "latest_events": "אירועים אחרונים",
    "latest_highlights": "אירועים מרכזיים אחרונים",
    "statistics": "סטטיסטיקות",
    "commerce_category_labels": {
      "free": "חינם",
      "member": "יש להתחבר על מנת לצפות",
      "ppv": "תשלום לפי צפייה",
      "subscription": "מנויים בלבד",
      "rental": "השכרה",
      "premium": "יש לרכוש מנוי על מנת לצפות"
    },
    "lock_event_message": {
      "member": "נא להיכנס כדי לצפות במשחק",
      "geo_blocking": "אנו מצטערים, אבל הסרטון שאתה מנסה להפעיל מוגבל מהאזור שלך",
      "event_restricted": "אירוע זה מוגבל למיקום שלך",
      "premium": "התוכן בתשלום. יש לבחור מבין האפשרויות הבאות -",
      "subscription": ""
    },
    "main": "ראשי",
    "game_highlights": "רגעי השיא מהמשחק",
    "player_highlights": "רגעי השיא של שחקנים",
    "watch_full_game": "צפייה במשחק מלא",
    "your_clips_highlight": "קליפים ורגעי השיא שלך",
    "create_highlight": "יצירת אירוע שיא מהמשחק",
    "access_code": "הזן קוד גישה",
    "ppv_price": "צפה מ-{price}",
    "access_time": "תקבל גישה ל",
    "full_access": "גישה מלאה",
    "limited_access": "גישה מוגבלת",
    "ppv_tickets": "כרטיסי PPV",
    "subscriptions": "מנויים",
    "release_date": "תאריך הוצאה",
    "months": "חודשים | \nחוֹדֶשׁ | \nחודשים",
    "weeks": "שבועות | \nשָׁבוּעַ | \nשבועות",
    "years": "שנים | \nשָׁנָה | \nשנים",
    "change_product": "שנה מוצר",
    "go_to_event": "עבור לאירוע",
    "go_to_account": "עבור לחשבון",
    "go_to_main_page": "עבור לעמוד הראשי",
    "name": "שֵׁם",
    "update_card": "עדכון כרטיס",
    "page_not_found": "הדף לא נמצא",
    "inactive": "לֹא פָּעִיל",
    "my_club": "המועדון שלי",
    "select_your_club": "בחר את המועדון שלך",
    "add_to_selected": "הוסף ל'נבחרים'",
    "remove_from_selected": "הסר מהנבחרים",
    "password_not_match": "הסיסמאות אינן תואמות",
    "search_by_sport_type": "חפש לפי סוג ספורט",
    "videos": "סרטונים",
    "category": "קטגוריה",
    "reset_password": "אנא הזן את הסיסמה החדשה שלך",
    "min_8_characters": "מינימום 8 תווים",
    "league": "לִיגָה",
    "here": "כאן",
    "suggested": "מוּצָע",
    "enter_old_password": "הזן סיסמה ישנה",
    "less": "פָּחוֹת",
    "more": "יותר",
    "durationRenew": "משך זמן: יחודש {duration} {units} לאחר הרכישה | \nמשך: יחודש ב {duration}",
    "duration": "משך זמן: בתוקף {duration} {units} לאחר הרכישה | \nמשך: תוקף {duration}",
    "archive": "ארכיון",
    "team": "קְבוּצָה",
    "team_1_logo": "לוגו קבוצה 1",
    "team_2_logo": "לוגו קבוצה 2",
    "stats": "סטטיסטיקה",
    "gameType_labels": {
      "game": "מִשְׂחָק",
      "other": "אַחֵר",
      "practice": "תרגול",
      "training": "הַדְרָכָה"
    },
    "fullName": "שם מלא",
    "panoramic_mode": "אתה במצב פנורמי",
    "postal_code": "מיקוד",
    "search_event_league_team": "חפש אירוע, ליגה או קבוצה",
    "back_to_site": "חזרה לאתר",
    "done": "סיים",
    "search_teams": "חפש קבוצות",
    "game_recap": "תקציר משחק",
    "play_by_play": "כל המהלכים",
    "your_clips": "הקליפים שלי"
  },
  "pages": {
    "auth_sign_up": {
      "enter_your_details_below": "נא מלא את הפרטים הבאים",
      "password_updated_successuly_text": "הסיסמה עודכנה בהצלחה",
      "already_have_account": "כבר נרשמת?",
      "follow_category_header": "לבחירתך קבוצות או ליגות מועדפות",
      "back_to_all_teams": "חזרה לכל הקבוצות",
      "follow_teams_header": "עקוב אחר הקבוצות המועדפות עליך",
      "back_to_all_categories": "חזרה לכל הקטגוריות"
    },
    "account": {
      "account_delete_button": "מחיקת חשבון",
      "tab_billing_title": "פרטי התשלום שלי",
      "tab_devices_title": "המכשירים שלי",
      "tab_general_title": "כללי",
      "tab_password_title": "סיסמה",
      "tab_subscription_title": "המנויים שלי",
      "account_delete_confirm": "האם את\\ה בטוח\\ה שברצונך למחוק את החשבון שלך?",
      "account_delete_description": "לא ניתן לבטל את הפעולה",
      "tab_ppv_access_text": "כרטיס PPV נותן גישה ל",
      "tab_ppv_no_ppv": "אין לך כרטיסי PPV. \nאנא עברו לדף האירוע כדי לקנות כרטיס.",
      "tab_ppv_title": "כרטיסי PPV",
      "account_delete_successfully": "החשבון שלך נמחק בהצלחה!",
      "account_update_successfully": "החשבון שלך עודכן בהצלחה!"
    },
    "auth_password": {
      "forgot_password_help_text": "יש לרשום את כתובת המייל לקבלת קישור לחידוש סיסמה",
      "back_to_sign_in": "חזרה לעמוד כניסה",
      "send_reset_link": "שליחת קישור לאיפוס הסיסמה",
      "set_new_password": "הגדרת סיסמה חדשה",
      "forgot_password_success_text": "דוא\"ל עם הוראות כיצד לאפס את הסיסמה שלך נשלח אל {email}. \nאם לא תקבל את האימייל תוך מספר דקות, לחץ על כפתור שלח מחדש למטה.",
      "resend_instruction": "שלח שוב הוראות"
    },
    "clubs": {
      "manage_following": "ניהול מועדפים",
      "choose_your_club": "בחר את המועדון שלך",
      "choose_your_club_message": "בחר את המועדון אליו תשויך"
    },
    "auth_login": {
      "device_no_longer_allowed_error": "המכשיר הנוכחי שלך כבר לא נמצא ברשימת המכשירים המותרים.\nאנא התחבר שוב."
    },
    "auth_login_limit": {
      "device_limit_reached": "הגעת למגבלת המכשירים",
      "remove_device_to_login": "יש להסיר לפחות מכשיר אחד בכדי להיכנס",
      "current_device": "מכשיר נוכחי"
    },
    "account_billing": {
      "card_cvv": "CVC / CVV",
      "card_expiry_date": "תאריך תפוגה",
      "card_holder_name": "שם בעל הכרטיס",
      "card_number": "מספר כרטיס",
      "button_back_to_billing_info": "חזרה לפרטי חיוב",
      "payment_history_empty_text": "לא נמצאה היסטוריית תשלומים",
      "payment_history_title": "היסטוריית תשלומים",
      "alert_credit_card_save_success": "פרטי כרטיס האשראי נשמרו בהצלחה",
      "alert_credit_card_update_success": "פרטי כרטיס האשראי עודכנו בהצלחה",
      "label_modal_confirm_credit_card_delete": "לאחר שתסיר את כרטיס האשראי שלך, המנוי שלך יבוטל גם כן. \nהתוכן עדיין זמין עבורך עד תאריך הסיום של התוכנית. \nהאם אתה בטוח שברצונך להסיר את כרטיס האשראי?",
      "alert_credit_card_delete_success": "כרטיס החיוב שלך נמחק בהצלחה",
      "payment_in_progress": "התשלום מתבצע",
      "payment_in_progress_message": "נא לא לסגור דף זה עד שהתשלום שלך יעובד."
    },
    "account_subscription": {
      "active_subscription": "מנוי פעיל",
      "button_subscribe_now": "הירשם עכשיו",
      "button_subscription_cancel": "ביטול רישום",
      "subscription_due_date": "תוקף המינוי",
      "subscription_expiration_date": "פג תוקפו על ידי",
      "subscription_last_payment_date": "תאריך תשלום אחרון",
      "subscription_next_charge_date": "תאריך תשלום הבא",
      "button_back_to_my_subscription": "חזרה למנוי שלי",
      "or_select_plan": "או בחר את המסלול שלך",
      "redeem_access_code_label": "ממש את קוד הגישה שלך כאן",
      "alert_access_code_redeem_not_found": "קוד הגישה המבוקש אינו קיים",
      "alert_access_code_redeemed_success": "קוד הגישה מומש בהצלחה",
      "alert_subscription_cancel_success": "המנוי בוטל בהצלחה",
      "label_plan_amount": "סכום התוכנית",
      "label_taxes": "מע\"מ",
      "label_total_billed": "סך החיובים",
      "tab_all_subscriptions": "כל המנויים",
      "tab_my_subscriptions": "המנויים שלי",
      "alert_access_code_redeem_code_used": "קוד הגישה המבוקש כבר בשימוש",
      "label_full_access": "גישה מלאה",
      "label_full_access_description": "גישה מלאה לכל התכנים באתר",
      "label_limited_access": "גישה מוגבלת",
      "label_limited_access_description": "גישה מוגבלת לכל התכנים באתר",
      "subscription_expires": "תוקף המנוי יפוג {date}",
      "payment_details": "{title}: פרטי תשלום",
      "no_subscriptions_text": "אין מנויים פעילים ברשימה.\n\nתחזור מאוחר יותר",
      "redeem_access_code_title": "מימוש קוד הגישה שלך",
      "button_back_to_my_tickets": "בחזרה לכרטיסים שלי",
      "list_subscriptions_empty": "רשימת המינויים הזמינים ריקה. \nתחזור מאוחר יותר",
      "button_subscription_keep": "שמור על מנוי",
      "cancel_subscription_modal_subtitle": "המנוי שלך יבוטל בתום תקופת החיוב שלך בתאריך {date}. \nאתה יכול לשנות את דעתך בכל עת לפני תאריך זה.",
      "cancel_subscription_modal_title": "האם אתה בטוח שברצונך לבטל את המנוי שלך?",
      "checkbox_apply_as_new_credit_card": "שמור את פרטי הכרטיס",
      "renew_subscription_expires": "המנוי חוזר על עצמו. \nתאריך החיוב הבא {date}"
    },
    "videos": {
      "last_viewed": "נצפה לאחרונה",
      "user_interesting": "אולי יעניין אותך",
      "description_no_items": "זה המקום לסרטונים האהובים עליך.\nשמור, סקור ושתף!",
      "title_no_items": "הוסף כמה סרטונים לנבחרים"
    },
    "clips": {
      "title_no_clips": "עדיין אין לך קליפים.\nכנסו לסרטון וצרו את הקליפ הראשון שלכם!",
      "title_no_highlights": "עדין לא יצרת אירועי שיא\nהיכנס.י לוידאו ליצירת אירוע שיא ראשון שלך",
      "title_no_items_with_selected_filter": "לא נמצאו פריטים עם מסנן זה"
    },
    "auth_sign_in": {
      "welcome_message": "ברוכים הבאים",
      "new_to": "חדש ל",
      "auth_terms": "ע”י לחיצה על כפתור המשך הנך מסכים\\מסכימה ל{terms_of_service} ול{privacy_policy} של החברה",
      "sign_in_with_provider": "היכנס עם האישורים של {provider} שלך"
    },
    "account_password": {
      "alert_password_update_success": "סיסמתך שונתה בהצלחה"
    },
    "league": {
      "all_rounds": "כל המחזורים"
    },
    "events": {
      "event_start_date": "המשחק יתחיל ב {date}",
      "failed_statistics": "טעינת הנתונים הסטטיסטיים הקשורים לאירוע זה נכשלה... אנא נסה שוב מאוחר יותר.",
      "modal_share_with_time": "התחל ב-{time}",
      "modal_share_title": "שתף את הסרטון",
      "tabs": {
        "event_tags": {
          "create_tag": "התחל לתייג",
          "title_no_tags_not_logged_in": "אין עדיין תגים ברשימה, אנא היכנס כדי ליצור תגים חדשים",
          "choose_player_color": "הזן שחקן'",
          "choose_tag": "בחר סוג תג",
          "make_tag_global": "הפוך את התג לגלובלי",
          "name_the_tag": "תן שם לתג",
          "tag_creating_successfully_text": "התג נוצר בהצלחה",
          "title_enabled_tagging": "אין תגים ברשימה",
          "update_tag": "עדכן תג",
          "tag_updating_successfully_text": "התג עודכן בהצלחה",
          "filters": {
            "by_player_number": "שחקן '",
            "by_type": "לפי סוג",
            "clear_all_btn": "נקה הכל |\nנקה הכל ({count}) |\nנקה הכל ({count})",
            "no_tags": "אין תגים התואמים למסנן שנבחר",
            "popup_title": "לְסַנֵן",
            "search_input_placeholder": "חפש תגיות ספציפיות",
            "show_results_btn": "הראה תוצאות",
            "select_all": "בחר הכל | \nבחר הכל ({count}) | \nבחר הכל ({count})",
            "by_team": "לפי צוות"
          },
          "tag_deleting_successfully_text": "התג נמחק בהצלחה",
          "is_external_content": "זהו תוכן חיצוני, אתה לא יכול ליצור תג",
          "title_no_tags": "עדיין לא תוייגו אירועים מעניינים במשחק",
          "pause_all_btn": "השהה הכל",
          "play_all_btn": "שחק הכל",
          "choose_team": "בחר צוות"
        },
        "event_videos": {
          "create_clip": "חתוך קליפ",
          "title_no_clips_not_logged_in": "אין עדיין קליפים ברשימה, אנא היכנס על מנת ליצור קליפים חדשים",
          "name_the_clip": "תן שם לקליפ",
          "clip_creating_successfully_text": "הקליפ שלך יהיה מוכן בקרוב",
          "title_enabled_clipping": "אין קליפים ברשימה",
          "title_no_clips": "עדיין לא נחתכו קליפים מהמשחק",
          "clip_deleted_successfully_text": "הקליפ נמחק בהצלחה",
          "rename_clip": "שנה את שם הקליפ",
          "clip_updating_successfully_text": "הקליפ עודכן בהצלחה"
        },
        "event_stats": {
          "empty_stats_data": "הנתונים הסטטיסטיים ריקים",
          "game_board_title": "סטטיסטיקות משחק",
          "score_board_title": "ציון"
        }
      },
      "viewing_conditions": "בחר את תנאי הצפייה שלך",
      "appreciation": "תודה!",
      "success_purchased": "רכשת בהצלחה את {subscription}",
      "failed_purchased_message": "אנא נסה שוב או הזן כרטיס אחר.",
      "purchased_declined": "התשלום נדחה",
      "watching_event_by_text": "אתה צופה באירוע הזה על ידי",
      "failed_code_applied_message": "אנא נסה שוב או הזן קוד אחר.",
      "failed_code_applied_title": "קוד לא תקין",
      "success_code_applied": "הקוד {title} הוחל בהצלחה",
      "submit_reedem_code_input_placeholder": "הזן את קוד הגישה שלך כאן",
      "failed_already_used_code": "כבר נעשה שימוש בקוד זה",
      "event_will_start_in_a_few_min": "האירוע יתחיל אוטומטית בעוד מספר דקות...",
      "player_errors": {},
      "game_video_login_message": "אנא היכנס כדי לצפות בסרטוני משחקים",
      "tags_login_message": "אנא היכנס כדי להציג תגים",
      "success_purchased_secondary": "הרכישה שלך הצליחה",
      "time_till_kick_off": "זמן עד לפתיחה:",
      "failed_purchased_message_2": "אנא נסה שוב או השתמש באמצעי תשלום אחר."
    },
    "club": {
      "favorite_club_creating_successfully_text": "המועדון שלך נבחר בהצלחה"
    },
    "categories": {
      "no_following_category_for_logged_user": "על מנת להתחיל לעקוב אחרי הקבוצות המועדפות עליך, לחץ {here}",
      "no_following_category_for_unlogged_user": "אנא {login} כדי להישאר מעודכן באירועים המועדפים עליך"
    }
  },
  "sport_types": {
    "americanFootball": "פוטבול",
    "beachvolleyball": "כדורעף חופים",
    "fieldhockey": "הוקי שדה",
    "icehockey": "הוקי קרח",
    "rollerhockey": "הוקי רולר",
    "badminton": "כדור נוצה",
    "baseball": "בייסבול",
    "basketball": "כדורסל",
    "bowling": "באולינג",
    "cricket": "קריקט",
    "floorball": "פלורבול",
    "futsal": "קטרגל",
    "gymnastics": "התעמלות",
    "handball": "כדוריד",
    "lacrosse": "לקרוס",
    "netball": "כדור רשת",
    "other": "אחר",
    "rugby": "רוגבי",
    "snooker": "סנוקר",
    "soccer": "כדורגל",
    "softball": "סופטבול",
    "swimming": "שחייה",
    "tennis": "טניס",
    "volleyball": "כדורעף",
    "waterpolo": "כדור מים",
    "wrestling": "היאבקות",
    "football": "כדורגל"
  },
  "baseFooter": {
    "rights": "כל הזכויות שמורות"
  },
  "footer_links": {
    "about_us": "אודות",
    "faq": "שאלות נפוצות",
    "privacy_policy": "פרטיות",
    "terms_of_service": "תנאי שימוש",
    "custom": "{custom}"
  },
  "countries": {
    "AD": "אנדורה",
    "AE": "איחוד האמירויות הערביות",
    "AF": "אפגניסטן",
    "AG": "אנטיגואה וברבודה",
    "AI": "אנגווילה",
    "AL": "אלבניה",
    "AM": "אַרְמֶנִיָה",
    "AO": "אנגולה",
    "AR": "ארגנטינה",
    "AS": "סמואה האמריקנית",
    "AT": "אוֹסְטְרֵיָה",
    "AU": "אוֹסטְרַלִיָה",
    "AW": "ארובה",
    "AZ": "אזרבייג'ן",
    "BA": "בוסניה והרצגובינה",
    "BB": "ברבדוס",
    "BD": "בנגלדש",
    "BE": "בלגיה",
    "BF": "בורקינה פאסו",
    "BG": "בולגריה",
    "BH": "בחריין",
    "BI": "בורונדי",
    "BJ": "בנין",
    "BM": "ברמודה",
    "BO": "בוליביה",
    "BR": "בְּרָזִיל",
    "BS": "איי בהאמה",
    "BT": "בהוטן",
    "BW": "בוצואנה",
    "BY": "בלארוס",
    "BZ": "בליז",
    "CA": "קנדה",
    "CF": "הרפובליקה המרכז - אפריקאית",
    "CG": "קונגו",
    "CH": "שוויץ",
    "CK": "איי קוק",
    "CL": "צ'ילה",
    "CM": "קמרון",
    "CN": "חרסינה",
    "CO": "קולומביה",
    "CR": "קוסטה ריקה",
    "CU": "קובה",
    "CX": "אי חג המולד",
    "CY": "קַפרִיסִין",
    "DE": "גֶרמָנִיָה",
    "DJ": "ג'יבוטי",
    "DK": "דנמרק",
    "DM": "דומיניקה",
    "DO": "הרפובליקה הדומיניקנית",
    "DZ": "אלג'יריה",
    "EC": "אקוודור",
    "EE": "אסטוניה",
    "EG": "מִצְרַיִם",
    "ER": "אריתריאה",
    "ES": "סְפָרַד",
    "ET": "אֶתִיוֹפִּיָה",
    "FI": "פינלנד",
    "FJ": "פיג'י",
    "FK": "איי פוקלנד",
    "FM": "מיקרונזיה",
    "FO": "איי פרו",
    "FR": "צָרְפַת",
    "GA": "גאבון",
    "GD": "גרנדה",
    "GE": "גאורגיה",
    "GF": "גיאנה הצרפתית",
    "GH": "גאנה",
    "GI": "גיברלטר",
    "GL": "גרינלנד",
    "GM": "גמביה",
    "GN": "גינאה",
    "GP": "גוואדלופ",
    "GQ": "גיניאה המשוונית",
    "GR": "יָוָן",
    "GS": "דרום ג'ורג'יה ואיי סנדוויץ' הדרומיים",
    "GT": "גואטמלה",
    "GU": "גואם",
    "GW": "גינאה-ביסאו",
    "GY": "גיאנה",
    "HN": "הונדורס",
    "HR": "קרואטיה",
    "HT": "האיטי",
    "HU": "הונגריה",
    "ID": "אִינדוֹנֵזִיָה",
    "IE": "אירלנד",
    "IL": "ישראל",
    "IN": "הוֹדוּ",
    "IO": "טריטוריית האוקיינוס ​​ההודי הבריטי",
    "IQ": "עִירַאק",
    "IR": "איראן",
    "IS": "אִיסלַנד",
    "IT": "אִיטַלִיָה",
    "JM": "ג'מייקה",
    "JO": "יַרדֵן",
    "JP": "יפן",
    "KE": "קניה",
    "KG": "קירגיזסטן",
    "KH": "קמבודיה",
    "KI": "קיריבטי",
    "KM": "קומורו",
    "KW": "כווית",
    "KY": "איי קיימן",
    "KZ": "קזחסטן",
    "LB": "לבנון",
    "LI": "ליכטנשטיין",
    "LK": "סרי לנקה",
    "LR": "ליבריה",
    "LS": "לסוטו",
    "LT": "ליטא",
    "LU": "לוקסמבורג",
    "LV": "לטביה",
    "LY": "לוב",
    "MA": "מָרוֹקוֹ",
    "MC": "מונקו",
    "MD": "מולדובה",
    "ME": "מונטנגרו",
    "MG": "מדגסקר",
    "MH": "איי מרשל",
    "ML": "מלי",
    "MM": "מיאנמר",
    "MN": "מונגוליה",
    "MP": "איי מריאנה הצפוניים",
    "MQ": "מרטיניק",
    "MR": "מאוריטניה",
    "MS": "מונטסראט",
    "MT": "מלטה",
    "MU": "מאוריציוס",
    "MV": "המלדיביים",
    "MW": "מלאווי",
    "MX": "מקסיקו",
    "MY": "מלזיה",
    "NA": "נמיביה",
    "NC": "קלדוניה החדשה",
    "NE": "ניז'ר",
    "NF": "האי נורפוק",
    "NG": "ניגריה",
    "NI": "ניקרגואה",
    "NL": "הולנד",
    "NO": "נורווגיה",
    "NP": "נפאל",
    "NR": "נאורו",
    "NU": "ניואה",
    "NZ": "ניו זילנד",
    "OM": "עומאן",
    "PA": "פנמה",
    "PE": "פרו",
    "PF": "פולינזיה הצרפתית",
    "PG": "פפואה גינאה החדשה",
    "PH": "פיליפינים",
    "PK": "פקיסטן",
    "PL": "פּוֹלִין",
    "PR": "פוארטו ריקו",
    "PT": "פּוֹרטוּגָל",
    "PW": "פלאו",
    "PY": "פרגוואי",
    "QA": "קטאר",
    "RO": "רומניה",
    "RS": "סרביה",
    "RW": "רואנדה",
    "SA": "ערב הסעודית",
    "SB": "איי שלמה",
    "SC": "איי סיישל",
    "SD": "סודן",
    "SE": "שבדיה",
    "SG": "סינגפור",
    "SI": "סלובניה",
    "SK": "סלובקיה",
    "SL": "סיירה לאון",
    "SM": "סן מרינו",
    "SN": "סנגל",
    "SV": "אל סלבדור",
    "TC": "איי טורקס וקאיקוס",
    "TD": "צ'אד",
    "TG": "טוגו",
    "TH": "תאילנד",
    "TJ": "טג'יקיסטן",
    "TK": "טוקלאו",
    "TM": "טורקמניסטן",
    "TN": "תוניסיה",
    "TO": "טונגה",
    "TR": "טורקיה",
    "TT": "טרינידד וטובגו",
    "TV": "טובאלו",
    "TW": "טייוואן",
    "UA": "אוקראינה",
    "UG": "אוגנדה",
    "UY": "אורוגוואי",
    "UZ": "אוזבקיסטן",
    "VE": "ונצואלה",
    "VU": "ונואטו",
    "WF": "וואליס ופוטונה",
    "WS": "סמואה",
    "YE": "תֵימָן",
    "YT": "מיוט",
    "ZA": "דרום אפריקה",
    "ZM": "זמביה",
    "ZW": "זימבבואה",
    "BN": "ברוניי",
    "CV": "קייפ ורדה",
    "CW": "קוראסאו",
    "HK": "הונג קונג SAR סין",
    "KP": "צפון קוריאה",
    "KR": "דרום קוריאה",
    "LA": "לאוס",
    "MO": "מקאו SAR סין",
    "PS": "טריטוריה פלסטינית",
    "RE": "איחוד",
    "RU": "רוּסִיָה",
    "SY": "סוּריָה",
    "TZ": "טנזניה",
    "GB": "הממלכה המאוחדת",
    "US": "ארצות הברית",
    "VG": "איי בתולה בריטיים",
    "VI": "איי הבתולה של ארצות הברית",
    "VN": "וייטנאם",
    "CD": "קונגו, דמ\nנציג של (זאיר)",
    "AX": "איי אלנד",
    "BQ": "הולנד הקאריבית",
    "EA": "סאוטה ומלילה",
    "EH": "סהרה המערבית",
    "GG": "גרנזי",
    "IC": "האיים הקנריים",
    "IM": "האי מאן",
    "JE": "ג'רזי",
    "MZ": "מוזמביק",
    "PN": "איי פיטקרן",
    "SO": "סומליה",
    "SS": "דרום סודן",
    "SX": "סנט מרטן",
    "TA": "טריסטן דה קוניה",
    "TF": "השטחים הדרומיים של צרפת",
    "VA": "עיר הותיקן",
    "XK": "קוסובו",
    "AC": "אי האסנסיון",
    "CZ": "צ'כיה",
    "CC": "איי קוקוס (קילינג).",
    "TL": "טימור-לסטה",
    "CI": "חוף השנהב",
    "MK": "צפון מקדוניה",
    "SR": "סורינאם",
    "SZ": "אסווטיני",
    "DG": "דייגו גרסיה"
  },
  "errors": {
    "expired_session_error": "פג תוקף ההפעלה שלך. \nנא להיכנס שוב.",
    "vod_files_not_found": "אופס! \nקבצי וידאו לא נמצאו",
    "vod_playback_failed": "אופס! \nההפעלה נכשלה במהלך זרם VOD",
    "could_not_find_event": "מצטערים, לא הצלחנו למצוא את האירוע שאתה מחפש.",
    "could_not_find_page": "מצטערים, לא הצלחנו למצוא את הדף שאתה מחפש.",
    "could_not_find_team": "מצטערים, לא הצלחנו למצוא את הצוות שאתה מחפש.",
    "event_not_found": "האירוע לא נמצא",
    "page_not_found": "הדף לא נמצא",
    "team_not_found": "הצוות לא נמצא",
    "could_not_find_results": "מצטערים, לא מצאנו תוצאות לחיפוש זה.",
    "ERR400CL11": "יצירת הקליפ נכשלה",
    "ERR400CM11": "התשלום נכשל",
    "ERR400CM12": "ההזמנה נכשלה",
    "ERR400CM13": "לכידת תשלום נכשל",
    "ERR400CM14": "פרטי סוחר שמירת המינוי נכשלו",
    "ERR400CM15": "אנא בדוק את פרטי כרטיס האשראי שלך",
    "ERR400CM16": "סופק ערך פלטפורמה לא חוקי",
    "ERR400GN00": "בקשה גרועה",
    "ERR400GN11": "קוד זהה לא חוקי סופק",
    "ERR400GN12": "שדות לא ניתנים לעדכון",
    "ERR400ID11": "סופקה זהות לא חוקית",
    "ERR400ID12": "פריט זהות לא חוקי סופק",
    "ERR400ID13": "לא ניתן להוסיף זהות לרשימת המועדונים",
    "ERR400ID14": "זוג זהות ופריט זהות לא חוקיים סופק",
    "ERR400TG11": "יצירת התג נכשלה",
    "ERR400UR11": "נדרשים שם פרטי ושם משפחה של הפרופיל",
    "ERR400UR12": "נדרש אימייל",
    "ERR400UR13": "למשתמש כבר יש כרטיס אשראי שמור",
    "ERR400UR14": "אנא מלא את השם הפרטי, שם המשפחה ושדות הדוא\"ל של הפרופיל שלך לפני שתשמור כרטיס אשראי",
    "ERR400UR17": "למשתמש אין כרטיס אשראי לעריכה",
    "ERR400UR18": "המנוי כבר בוטל",
    "ERR400UR19": "המנוי אינו פעיל",
    "ERR400UR20": "נדרשים שם משתמש וסיסמה",
    "ERR400UR21": "החשבון אינו פעיל",
    "ERR401AT11": "נדרש אסימון",
    "ERR401AT12": "אסימון איפוס סיסמה לא חוקי",
    "ERR401AT13": "אסימון רענון לא חוקי",
    "ERR401AT14": "מנפיק נושא לא ידוע",
    "ERR401AT15": "סוג אסימון נושא לא נתמך",
    "ERR401AT16": "אסימון לא חוקי",
    "ERR401AT17": "אסימון שפג תוקפו",
    "ERR401AT18": "השגת אימייל מהאסימון נכשלה",
    "ERR401AT19": "המשתמש לא נמצא",
    "ERR401GN00": "לא מורשה",
    "ERR401UR11": "שם המשתמש או הסיסמה שגויים. \nאנא בדוק את האישורים שלך.",
    "ERR402GN00": "נדרש תשלום",
    "ERR403GN00": "אסור",
    "ERR403TG11": "המשתמש לא יכול לעדכן תגים גלובליים",
    "ERR403TG12": "המשתמש לא יכול ליצור תגים גלובליים",
    "ERR403TG13": "המשתמש אינו יכול למחוק את התגים שלא נוצרו על ידי אחד",
    "ERR403TG14": "המשתמש אינו יכול למחוק תגים גלובליים",
    "ERR404CM11": "לא ניתן לקבל מנויים",
    "ERR404CM12": "קוד גישה לא נמצא",
    "ERR404EV11": "לא ניתן לקבל מידע על האירוע",
    "ERR404EV12": "האירוע או ה-VOD לא נמצאו",
    "ERR404GN00": "לא נמצא",
    "ERR404ID00": "זהות לא נמצאה",
    "ERR404ID11": "לא ניתן היה לאחזר את הזהות",
    "ERR404PR00": "מוצר לא נמצא",
    "ERR404PR11": "לא יכול לקבל מידע ppv",
    "ERR404TG00": "התג לא נמצא",
    "ERR404TM00": "הצוות לא נמצא",
    "ERR404UR00": "המשתמש לא נמצא",
    "ERR404UR11": "ההזמנה לא נמצאה",
    "ERR404UR12": "מנוי לא נמצא",
    "ERR404UR13": "משתמש לא קיים",
    "ERR404UR14": "הפרופיל לא קיים",
    "ERR404UR15": "כרטיס לא נמצא עבור משתמש זה",
    "ERR404UR16": "הסרטון לא נמצא ברשימת הצפייה",
    "ERR404VD11": "לא ניתן היה לאחזר Vod",
    "ERR409CM11": "קוד גישה כבר בשימוש",
    "ERR409GN00": "סְתִירָה",
    "ERR409PR12": "המוצר כבר נרכש",
    "ERR409UR11": "המועדון כבר נוסף",
    "ERR409UR12": "משתמש כבר רשום",
    "ERR422GN00": "תוכן בלתי ניתן לעיבוד",
    "ERR451GN00": "לא זמין מסיבות משפטיות",
    "ERR500GN00": "שגיאת שרת פנימית",
    "ERR500NT12": "שליחת האימייל לאיפוס הסיסמה נכשלה",
    "ERR501GN00": "לא מיושם",
    "ERR502GN00": "שער רע",
    "ERR503GN00": "שירותים לא זמינים",
    "ERR504GN00": "פסק זמן של שער",
    "could_not_find_league": "מצטערים, לא הצלחנו למצוא את הליגה שאתה מחפש.",
    "default_error_message": "אירעה שגיאת שרת. \nבבקשה נסה שוב מאוחר יותר",
    "device_limit_exceeded": "חרגת ממגבלת המכשירים!",
    "league_not_found": "הליגה לא נמצאה",
    "vod_corrupted_files": "לא ניתן היה לטעון את הסרטון",
    "passwords_dont_match": "הסיסמאות אינן תואמות",
    "incomplete_cvc": "קוד האבטחה של הכרטיס שלך אינו שלם!",
    "incomplete_expiry": "תאריך התפוגה של הכרטיס שלך אינו שלם!",
    "incomplete_number": "מספר הכרטיס שלך לא מלא!",
    "invalid_expiry_month_past": "שנת התפוגה של הכרטיס שלך היא בעבר!",
    "invalid_expiry_year": "שנת התפוגה של הכרטיס שלך אינה חוקית!",
    "invalid_expiry_year_past": "שנת התפוגה של הכרטיס שלך היא בעבר!",
    "invalid_number": "מספר הכרטיס שלך לא חוקי!",
    "popup_closed": "החלון נסגר",
    "login_failed": "ההתחברות נכשלה",
    "ERR400UR24": "הסיסמה הישנה שגויה. \nאנא בדוק את האישור שלך",
    "live_stream_error_message": "נראה שיש עיכוב קל בשידור החי. אנחנו עובדים במרץ כדי לפתור את הבעיה במהירות האפשרית. תודה על הסבלנות!"
    },
  "tags": {
    "baseball_bottom_1st": "1 תחתון",
    "baseball_bottom_2nd": "2 תחתון",
    "baseball_double": "לְהַכפִּיל",
    "baseball_double_play": "משחק כפול",
    "baseball_fly_out": "עוף החוצה",
    "baseball_ground_out": "קרקע החוצה",
    "baseball_hit": "מכה",
    "baseball_home_run": "ריצה הביתה",
    "baseball_popout": "קופץ",
    "baseball_run": "לָרוּץ",
    "baseball_single": "יחיד",
    "baseball_stolen_base": "בסיס גנוב",
    "baseball_strikeout": "פסילה",
    "baseball_substitution": "החלפה",
    "baseball_top_1st": "במקום הראשון",
    "baseball_top_2nd": "העליון 2",
    "baseball_triple_play": "משחק משולש",
    "baseball_tripple": "3 נקודות",
    "baseball_walk": "צעדים",
    "basketball_alley_oop": "אלי-הופ",
    "basketball_assist": "אסיסט",
    "basketball_big_play": "היילייט",
    "basketball_block": "חסימה",
    "basketball_dunk": "הטבעה",
    "basketball_momentym": "תְנוּפָה",
    "basketball_money_time": "מומנטום",
    "basketball_substitution": "חילוף",
    "basketball_three_point": "שלוש נקודות",
    "beach_volleyball_dig": "לַחפּוֹר",
    "beach_volleyball_double": "לְהַכפִּיל",
    "beach_volleyball_free_ball": "כדור חופשי",
    "beach_volleyball_match_point": "נקודת שוויון",
    "beach_volleyball_mishit": "טעות",
    "beach_volleyball_serve": "לְשָׁרֵת",
    "beach_volleyball_set_point": "הגדר נקודה",
    "beach_volleyball_shank": "שוק",
    "beach_volleyball_side_out": "הצד החוצה",
    "beach_volleyball_spike": "דָרְבָּן",
    "beach_volleyball_substitution": "החלפה",
    "field_ball_loss": "איבוד כדור",
    "field_ball_possesion": "החזקת כדור",
    "field_circle_penetration": "חדירת מעגל",
    "field_hockey_4v4": "4v4",
    "field_hockey_big_hit": "להיט גדול",
    "field_hockey_fight": "מַאֲבָק",
    "field_hockey_goal": "מטרה",
    "field_hockey_other": "אַחֵר",
    "field_hockey_power_play": "משחק כוח",
    "field_hockey_save": "להציל",
    "field_hockey_shot": "בְּעִיטָה",
    "field_hockey_substitution": "החלפה",
    "field_interception": "יירוט",
    "field_penalty_corner": "פינת עונשין",
    "field_press_fp_hc": "ללחוץ",
    "field_time_penalty": "עונש זמן",
    "field_turn_over": "לְהִתְהַפֵּך",
    "football_big_catch": "מלכוד גדול",
    "football_big_hit": "להיט גדול",
    "football_big_run": "ריצה גדולה",
    "football_field_goal": "שער שדה",
    "football_kick_return": "בעיטה חזרה",
    "football_kickoff": "לבעוט",
    "football_punt_return": "החזרת נקודות",
    "football_substitution": "החלפה",
    "football_touch_down": "לִנְחוֹת",
    "futsal_corner_kick": "בעיטת קרן",
    "futsal_foul": "עבירה",
    "futsal_free_kick": "בעיטה חופשית",
    "futsal_goal": "שער",
    "futsal_header": "נגיחה",
    "futsal_penalty": "פנדל",
    "futsal_redcard": "כרטיס אדום",
    "futsal_save": "הצלה",
    "futsal_shot": "בעיטה",
    "futsal_shot_on_target": "בעיטה למסגרת",
    "futsal_substitution": "חילוף",
    "futsal_yellowcard": "כרטיס צהוב",
    "general_bad": "רַע",
    "general_drill": "תרגיל",
    "general_good": "טוֹב",
    "gymnastic_wow": "וואו",
    "handball_foul": "עבירה",
    "handball_goal": "שער",
    "handball_penalty_shot": "זריקת עונשין",
    "handball_red_card": "כרטיס אדום",
    "handball_save": "הצלה",
    "handball_shot_on_goal": "זריקה למסגרת",
    "handball_substitution": "חילוף",
    "handball_suspension": "השעיה",
    "handball_yellow_card": "כרטיס צהוב",
    "hockey_4v4": "4v4",
    "hockey_big_hit": "להיט גדול",
    "hockey_fight": "מַאֲבָק",
    "hockey_goal": "מטרה",
    "hockey_power_play": "משחק כוח",
    "hockey_save": "להציל",
    "hockey_shot": "בְּעִיטָה",
    "hockey_substitution": "החלפה",
    "lacrosse_clear": "ברור",
    "lacrosse_face_off": "נאבקת מול",
    "lacrosse_goal": "מטרה",
    "lacrosse_ground_ball": "כדור קרקע",
    "lacrosse_man_up": "תתגבר",
    "lacrosse_penalty": "עוֹנֶשׁ",
    "lacrosse_save": "להציל",
    "lacrosse_shot": "בְּעִיטָה",
    "lacrosse_substitution": "החלפה",
    "other_wow": "אחר וואו",
    "roller_ball_loss": "איבוד כדור",
    "roller_ball_possesion": "החזקת כדור",
    "roller_circle_penetration": "חדירת מעגל",
    "roller_hockey_4v4": "4v4",
    "roller_hockey_big_hit": "להיט גדול",
    "roller_hockey_fight": "מַאֲבָק",
    "roller_hockey_goal": "מטרה",
    "roller_hockey_other": "אַחֵר",
    "roller_hockey_power_play": "משחק כוח",
    "roller_hockey_save": "להציל",
    "roller_hockey_shot": "בְּעִיטָה",
    "roller_hockey_substitution": "החלפה",
    "roller_interception": "יירוט",
    "roller_penalty_corner": "פינה",
    "roller_press_fp_hc": "ללחוץ",
    "roller_time_penalty": "עונש זמן",
    "roller_turn_over": "לְהִתְהַפֵּך",
    "rugby_break_down": "התקלקל",
    "rugby_conversion": "הֲמָרָה",
    "rugby_drop_goal": "ירידה במטרה",
    "rugby_garry_owen": "גארי אוון",
    "rugby_grubber": "גרובר",
    "rugby_knock_on": "לדפוק על",
    "rugby_maul": "מַפֵּץ",
    "rugby_penalty_try": "ניסיון עונשין",
    "rugby_substitution": "החלפה",
    "soccer_corner_kick": "בעיטת קרן",
    "soccer_foul": "עבירה",
    "soccer_free_kick": "בעיטה חופשית",
    "soccer_goal": "שער",
    "soccer_header": "נגיחה",
    "soccer_penalty": "פנדל",
    "soccer_redcard": "כרטיס אדום",
    "soccer_save": "הצלה",
    "soccer_shot": "בעיטה",
    "soccer_shot_on_target": "בעיטה למסגרת",
    "soccer_substitution": "חילוף",
    "soccer_yellowcard": "כרטיס צהוב",
    "volleyball_dig": "לַחפּוֹר",
    "volleyball_double": "לְהַכפִּיל",
    "volleyball_free_ball": "כדור חופשי",
    "volleyball_match_point": "נקודת שוויון",
    "volleyball_mishit": "טעות",
    "volleyball_serve": "לְשָׁרֵת",
    "volleyball_set_point": "הגדר נקודה",
    "volleyball_shank": "שוק",
    "volleyball_side_out": "הצד החוצה",
    "volleyball_spike": "דָרְבָּן",
    "volleyball_substitution": "החלפה",
    "wrestling_wow": "וואו"
  }
}
